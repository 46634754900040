import MapGL, { Marker, NavigationControl } from "@urbica/react-map-gl";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import MarkdownIt from "markdown-it";
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import {
	FaBuilding,
	FaHeart,
	FaMapMarkerAlt,
	FaPlay,
	FaRegHeart,
	FaRegEdit,
} from "react-icons/fa";
import { MdMonitor, MdOutlineClear } from "react-icons/md";
import ContactFormHome from "../components/contact-form-home";
import ContactFormListings from "../components/contact-form-listings";
import FacilitiesBlock from "../components/facilities-block";
import FeaturedProperties from "../components/featured-properties";
import ImageSlider from "../components/image-slider";
import Layout from "../components/layout";
import MapCardModalNew from "../components/map-card-modal-new";
import PreferToTalk from "../components/prefer-to-talk";
import PricingSeciton from "../components/pricing-section";
import TubeDistance from "../components/tube-distance";
import VerticalListingsPropertyCard from "../components/vertical-listings-property-card";
import CartContext from "../context/CartContext";
import ExpertCard from "../components/expert-card";
import PopupForm from "../components/popup-form";
import ExpertCardPopup from "../components/expert-card-popup";
import { FaArrowLeftLong } from "react-icons/fa6";

const slugify = require("slugify");
function round5(x) {
	return Math.ceil(x / 5) * 5;
}

const Listings = ({
	data: {
		property,
		site,
		properties: { edges: properties },
	},
}) => {
	//   const paddington = data.paddington?.localFile.childImageSharp.gatsbyImageData;

	//   const canaryWharf =
	//     data.canaryWharf?.localFile.childImageSharp.gatsbyImageData;
	//   const piccadilly = data.piccadilly?.localFile.childImageSharp.gatsbyImageData;
	//   const westminster =
	//     data.westminster?.localFile.childImageSharp.gatsbyImageData;

	const {
		subwayStation1,
		subwayStation1DistanceKm,
		subwayStation1DurationMins,
		subwayStation1Lines,
		subwayStation2,
		subwayStation2DistanceKm,
		subwayStation2DurationMins,
		subwayStation2Lines,
		subwayStation3,
		subwayStation3DistanceKm,
		subwayStation3DurationMins,
		subwayStation3Lines,
		airtableId,
		locationLatitude,
		locationLongitude,
		name: propertyName,
		services,
		virtualTourLink,
		amenities,
		type,
		desksFrom,
		rentPM,
		floorsAvailable,
		desksTo,
		features,
		description,
		addressLineOne,
		addressLineTwo,
		city,
		postCode,
		live,
		photos,
		videoId,
		fixedId,
	} = property;

	const {
		cart,
		viewport,
		numberOfPeople,
		OnAddToCart,
		OnRemoveFromCart,
		budgetPerDesk,
		onLocationChangeScroll,
		onLocationChangePan,
		shortOffices,
	} = React.useContext(CartContext);

	const [activePopup, setActivePopup] = useState(-1);
	const [showPopup, setShowPopup] = useState(false);
	const [modalShow, setModalShow] = React.useState(false);
	const [modalShowDesktop, setModalShowDesktop] = React.useState(false);
	const md = new MarkdownIt();
	const [vidShow, setVidShow] = useState(false);
	const descriptionNew = description || "";
	const htmlDescription = md.render(descriptionNew) || "";
	const handleClose = () => setVidShow(false);
	const handleShow = () => setVidShow(true);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formSubmitted2, setFormSubmitted2] = useState(false);

	const handleFormSuccess = () => {
		setFormSubmitted(true);
	};
	const handleFormSuccess2 = () => {
		setFormSubmitted2(true);
	};

	const upperCapacity = Math.min(desksTo, numberOfPeople?.max);
	const lowerCapacity = Math.max(desksFrom, numberOfPeople?.min);

	var floors = [];

	useEffect(() => {
		if (property?.floorsAvailable !== null) {
			floors =
				property?.floorsAvailable
					?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
					.slice() || [];
		}
	}, [property]);

	const popupFalse = () => {
		setShowPopup(false);
	};

	const [formShow, setFormShow] = useState(false);

	const handleFormShow = () => setFormShow(true);
	const handleFormClose = () => setFormShow(false);

	const mapRef = useRef();
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Listings",
				item: {
					url: `${siteUrl}/listings`,
					id: `${siteUrl}/listings`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${propertyName}`,
				item: {
					url: `${siteUrl}/listings/${slugify(propertyName)}-${fixedId}`,
					id: `${siteUrl}/listings/${slugify(propertyName)}-${fixedId}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{live === true && (
				<GatsbySeo
					title={`${propertyName} | Flex Office Solutions`}
					description={description?.replace(/â€™/g, "'")}
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/listings/${slugify(propertyName)}-${fixedId}`,
						title: `${propertyName} | Flex Office Solutions`,
						description: description?.replace(/â€™/g, "'"),
						images: [
							{
								url: `${photos[0]}`,
								width: 1920,
								height: 1080,
								alt: "Property main image",
							},
						],
					}}
				/>
			)}
			{live !== true && (
				<GatsbySeo
					title={`${propertyName} | Flex Office Solutions old`}
					language="en"
					noindex
				/>
			)}
			<Modal
				style={{ zIndex: 9999999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="lg"
				dialogClassName="transparent-modal"
				show={formShow}
				centered
				onHide={handleFormClose}
				className=" w-100 p-4  "
			>
				<div className="position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={handleFormClose}
						/>
					</div>
					<Modal.Body
						style={{
							overflow: "hidden",

							borderRadius: "12px",
						}}
						className="p-0 w-100  "
						closeButton
					>
						{!formSubmitted2 ? (
							<>
								<div
									style={{
										borderBottomLeftRadius: "12px",
										borderBottomRightRadius: "12px",
									}}
									className="py-4 px-3 px-md-4 px-lg-5 mb-3 bg-white "
								>
									<Row>
										<Col>
											<h3 className="pb-0 ">
												Get a quote for{" "}
												<span className="text-dark-blue fw-bold">
													{propertyName}
												</span>
											</h3>
										</Col>
									</Row>
									<Row>
										<Col>
											<p>
												Contact us today to receive a personalised quote and
												find your ideal workspace.
											</p>
										</Col>
									</Row>
									<PopupForm
										formPropertyName={propertyName}
										subject="Get a quote"
										onSuccess={handleFormSuccess2}
									/>
								</div>
								<ExpertCardPopup />
							</>
						) : (
							<div className="py-5 text-center px-3 px-md-4 px-lg-5  bg-white ">
								<h2 className="text-uppercase fs-1 text-primary">Thank You!</h2>
								<p style={{ fontWeight: "600" }} className="pb-4">
									We’ll reach out to you soon!
								</p>
								<p style={{ fontWeight: "600" }}>
									Feel free to call us on{" "}
									<a style={{ fontWeight: "600" }} href="tel:02072062610">
										0207 206 2610
									</a>{" "}
									or email{" "}
									<a
										style={{ fontWeight: "600" }}
										href="mailto:hello@flexofficesolutions.co.uk"
									>
										hello@flexofficesolutions.co.uk
									</a>
								</p>
							</div>
						)}
					</Modal.Body>
				</div>
			</Modal>
			<Modal
				style={{ zIndex: 99999 }}
				size="lg"
				show={vidShow}
				onHide={handleClose}
				centered
			>
				<Modal.Body className="bg-black" closeButton>
					<div className="  text-center position-relative">
						<div className="position-absolute text-white end-0 top-0 p-lg-3">
							<MdOutlineClear
								style={{ cursor: "pointer" }}
								className="fs-4 "
								onClick={handleClose}
							/>
						</div>
						<div className="pb-5 pt-5">
							<div style="padding:56.25% 0 0 0;position:relative;">
								<iframe
									src={`https://player.vimeo.com/video/${videoId}?autoplay=1`}
									style="position:absolute;top:0;left:0;width:100%;height:100%;"
									frameborder="0"
									allow="autoplay; fullscreen; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
							<script src="https://player.vimeo.com/api/player.js"></script>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<section>
				<Container>
					<Row>
						<Col xs={12}>
							<div className="pt-lg-5 pt-3 pb-2">
								<Link
									to="/properties"
									className="black-link d-flex mb-2 align-items-center"
								>
									<FaArrowLeftLong className="me-2 mt-1" /> Back to search
									results
								</Link>
								<h1
									style={{ cursor: "pointer" }}
									onClick={handleFormShow}
									className="text-black text-center text-lg-start "
								>
									{propertyName}
								</h1>
								<p
									style={{ cursor: "pointer" }}
									onClick={handleFormShow}
									className="text-black text-center text-lg-start"
								>
									<FaMapMarkerAlt />{" "}
									{`${addressLineOne ? `${addressLineOne}, ` : ""} ${
										addressLineTwo ? `${addressLineTwo}, ` : ""
									}${city ? `${city}, ` : ""}${postCode ? `${postCode}` : ""}`}
								</p>
							</div>
						</Col>
					</Row>
					<Row className="gx-5">
						<Col lg={6}>
							<ImageSlider imageDataArr={photos} height="450px" className="" />
							<Row className="py-2">
								{virtualTourLink !== null && videoId !== null && (
									<Col
										xs={6}
										md={4}
										lg={12}
										xl={4}
										className=" mt-2 mt-md-0 text-center text-lg-start "
									>
										{cart?.filter((e) => e.name === propertyName).length >
											0 && (
											<div
												className="d-flex justify-content-center justify-content-md-start align-items-center"
												onClick={() => OnRemoveFromCart(property)}
												style={{ cursor: "pointer" }}
											>
												<FaHeart
													style={{ cursor: "pointer" }}
													className="me-2 fs-3 text-dark-blue"
												/>
												<p className="m-0 p-0 text-black fs-5">
													Remove from shortlist
												</p>
											</div>
										)}
										{cart?.filter((e) => e.name === propertyName).length <
											1 && (
											<div
												className="d-flex justify-content-center justify-content-md-start align-items-center"
												onClick={() => OnAddToCart(property)}
												style={{ cursor: "pointer" }}
											>
												<FaRegHeart
													style={{ cursor: "pointer" }}
													className="me-2 fs-3 text-med-grey"
												/>
												<p className="m-0 p-0 text-black fs-5">
													Add to shortlist
												</p>
											</div>
										)}
									</Col>
								)}
								{((virtualTourLink === null && videoId === null) ||
									(virtualTourLink !== null && videoId === null) ||
									(virtualTourLink === null && videoId !== null)) && (
									<Col
										xs={6}
										className=" mt-2 mt-md-0 text-center text-lg-start "
									>
										{cart?.filter((e) => e.name === propertyName).length >
											0 && (
											<div
												className="d-flex justify-content-center justify-content-md-start align-items-center"
												onClick={() => OnRemoveFromCart(property)}
												style={{ cursor: "pointer" }}
											>
												<FaHeart
													style={{ cursor: "pointer" }}
													className="me-2 fs-3 text-dark-blue"
												/>
												<p className="m-0 p-0 text-black fs-5">
													Remove from shortlist
												</p>
											</div>
										)}
										{cart?.filter((e) => e.name === propertyName).length <
											1 && (
											<div
												className="d-flex justify-content-center justify-content-md-start align-items-center"
												onClick={() => OnAddToCart(property)}
												style={{ cursor: "pointer" }}
											>
												<FaRegHeart
													style={{ cursor: "pointer" }}
													className="me-2 fs-3 text-med-grey"
												/>
												<p className="m-0 p-0 text-black fs-5">
													Add to shortlist
												</p>
											</div>
										)}
									</Col>
								)}

								<Col
									md={4}
									xs={6}
									className="text-center d-lg-none mt-2 mt-md-0"
								>
									<a href="#contact">
										<div className="d-flex justify-content-md-center justify-content-lg-start justify-content-xl-center justify-content-center align-items-center ">
											<FaRegEdit
												style={{ cursor: "pointer" }}
												className="me-2 fs-3 text-med-grey"
											/>
											<p className="m-0 p-0 text-black fs-5">Get a quote</p>
										</div>
									</a>
								</Col>

								{virtualTourLink !== null && videoId !== null && (
									<Col
										xs={6}
										md={4}
										lg={6}
										xl={4}
										className="text-center mt-3 mt-md-0 mt-lg-2 mt-xl-0 "
									>
										<a target="_blank" rel="noreferrer" href={virtualTourLink}>
											<div className="d-flex justify-content-md-center justify-content-lg-start justify-content-xl-center justify-content-center align-items-center ">
												<MdMonitor className="me-2 fs-3 text-med-grey" />
												<p className="m-0 p-0 text-black fs-5">Virtual Tour</p>
											</div>
										</a>
									</Col>
								)}
								{virtualTourLink !== null && videoId === null && (
									<Col md={6} className="text-center mt-3 mt-md-0  ">
										<a target="_blank" rel="noreferrer" href={virtualTourLink}>
											<div className="d-flex justify-content-md-end  justify-content-center align-items-center ">
												<MdMonitor className="me-2 fs-3 text-med-grey" />
												<p className="m-0 p-0 text-black fs-5">Virtual Tour</p>
											</div>
										</a>
									</Col>
								)}
								{virtualTourLink !== null && videoId !== null && (
									<Col
										xs={6}
										md={4}
										lg={6}
										xl={4}
										className="text-end mt-3 mt-md-0 mt-lg-2 mt-xl-0 "
									>
										<a style={{ cursor: "pointer" }} onClick={handleShow}>
											<div className="d-flex justify-content-md-start justify-content-center align-items-center ">
												<FaPlay className="me-2 fs-4 text-med-grey" />
												<p className="m-0 p-0 text-black fs-5">Video Tour</p>
											</div>
										</a>
									</Col>
								)}
								{virtualTourLink === null && videoId !== null && (
									<Col md={6} className="text-end mt-3 mt-md-0  ">
										<a style={{ cursor: "pointer" }} onClick={handleShow}>
											<div className="d-flex justify-content-md-end justify-content-center align-items-center ">
												<FaPlay className="me-2 fs-4 text-med-grey" />
												<p className="m-0 p-0 text-black fs-5">Video Tour</p>
											</div>
										</a>
									</Col>
								)}
								{/* <Col
									lg={6}
									className="text-end d-flex justify-content-end d-lg-none pt-3"
								>
									<a href="#contact">Find out more</a>
								</Col> */}
							</Row>
						</Col>
						<Col lg={6} className="d-none d-lg-block pb-3 pb-lg-4">
							{!formSubmitted ? (
								<>
									<div
										style={{
											boxShadow: "0px 1px 20px #00000029",
											borderRadius: "12px",
										}}
										className="pt-5 pb-6 px-5 mb-3 bg-white "
									>
										<Row>
											<Col>
												<h3>
													Book a viewing of{" "}
													<span className="text-dark-blue fw-bold">
														{propertyName}
													</span>
												</h3>
											</Col>
										</Row>
										<Row>
											<Col>
												<p>
													Explore our instantly available office spaces at your
													convenience and schedule a flexible viewing today to
													find your ideal workspace.
												</p>
											</Col>
										</Row>
										<ContactFormListings
											subject="Book a viewing"
											onSuccess={handleFormSuccess}
											formPropertyName={propertyName}
										/>
									</div>
									<ExpertCard />
								</>
							) : (
								<div
									style={{
										boxShadow: "0px 1px 20px #00000029",
										borderRadius: "12px",
									}}
									className="py-5 text-center px-3 px-md-4 px-lg-5  bg-white "
								>
									<h2 className="text-uppercase fs-1 text-primary">
										Thank You!
									</h2>
									<p style={{ fontWeight: "600" }} className="pb-4">
										We’ll reach out to you soon!
									</p>
									<p style={{ fontWeight: "600" }}>
										Feel free to call us on{" "}
										<a style={{ fontWeight: "600" }} href="tel:02072062610">
											0207 206 2610
										</a>{" "}
										or email{" "}
										<a
											style={{ fontWeight: "600" }}
											href="mailto:hello@flexofficesolutions.co.uk"
										>
											hello@flexofficesolutions.co.uk
										</a>
									</p>
								</div>
							)}
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<Row>
						<Col>
							<hr className="my-1 " />
							{(budgetPerDesk?.perDesk || floorsAvailable !== null) && (
								<PricingSeciton
									property={property}
									otherOptions={shortOffices
										?.filter((office) => office.name !== propertyName)
										.slice(0, 2)}
								/>
							)}
							{!budgetPerDesk?.perDesk && floorsAvailable === null && (
								<>
									<h2 className="fs-1 text-center text-lg-start my-4">
										Pricing
									</h2>
									<Row>
										<Col xl={6}>
											<blockquote>
												<p className="mb-1 fs-2">
													From {lowerCapacity} desk
													{desksFrom === 1 ? "" : "s"} private office space
												</p>

												<h2 className={`mt-1 fs- 1 fw-bold display-${5}`}>
													£
													{`${
														rentPM === null || rentPM === undefined
															? "POA*"
															: `${round5(
																	lowerCapacity * rentPM
															  ).toLocaleString("en-UK")}pcm*`
													}`}
												</h2>
											</blockquote>
										</Col>
										<Col xl={6}>
											<blockquote className="mt-4 mt-xl-0">
												<p className="mb-1 fs-2">
													Up to {upperCapacity} desk
													{desksTo === 1 ? "" : "s"} private office space
												</p>

												<h2 className={`mt-1 fs- 1 fw-bold display-${5}`}>
													£
													{`${
														rentPM === null || rentPM === undefined
															? "POA*"
															: `${round5(
																	upperCapacity * rentPM
															  ).toLocaleString("en-UK")}pcm*`
													}`}
												</h2>
											</blockquote>
										</Col>
									</Row>
									<Row className="justify-content-end">
										<Col xl={6}>
											<p>
												*Guideline rental prices subject to size of space and
												availability
											</p>
										</Col>
									</Row>
								</>
							)}
						</Col>
					</Row>
				</Container>
			</section>
			<section className="d-lg-none py-3">
				<Container>
					{!formSubmitted ? (
						<>
							<div
								style={{
									boxShadow: "0px 1px 20px #00000029",
									borderRadius: "12px",
								}}
								className="py-3 py-md-5 px-3 px-md-5 mb-3 bg-white "
							>
								<Row>
									<Col>
										<h3>
											Book a viewing of{" "}
											<span className="text-dark-blue fw-bold">
												{propertyName}
											</span>
										</h3>
									</Col>
								</Row>
								<Row>
									<Col>
										<p>
											Explore our instantly available office spaces at your
											convenience and schedule a flexible viewing today to find
											your ideal workspace.
										</p>
									</Col>
								</Row>
								<ContactFormListings
									subject="Book a viewing"
									onSuccess={handleFormSuccess}
									formPropertyName={propertyName}
								/>
							</div>
							<ExpertCard />
						</>
					) : (
						<div
							style={{
								boxShadow: "0px 1px 20px #00000029",
								borderRadius: "12px",
							}}
							className=" text-center py-3 py-md-5 px-3 px-md-5 mb-3 bg-white "
						>
							<h2 className="text-uppercase fs-1 text-primary">Thank You!</h2>
							<p style={{ fontWeight: "600" }} className="pb-4">
								We’ll reach out to you soon!
							</p>
							<p style={{ fontWeight: "600" }}>
								Feel free to call us on{" "}
								<a style={{ fontWeight: "600" }} href="tel:02072062610">
									0207 206 2610
								</a>{" "}
								or email{" "}
								<a
									style={{ fontWeight: "600" }}
									href="mailto:hello@flexofficesolutions.co.uk"
								>
									hello@flexofficesolutions.co.uk
								</a>
							</p>
						</div>
					)}
				</Container>
			</section>
			{description && (
				<section>
					<Container>
						<hr className="mb-5" />
						<Row className="justify-content-center">
							<Col lg={6} className="mb-4 mb-lg-3">
								<h2 className="fs-1">Description of the office</h2>
								<p className="mt-3" style={{ fontSize: "1.2rem" }}>
									{parse(htmlDescription)}
								</p>
							</Col>
							<Col
								className="d-none d-lg-block"
								md={6}
								lg={{ span: 5, offset: 1 }}
							>
								<div className="px-xl-6 mb-4">
									<PreferToTalk title="Ready to talk?" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			)}
			<section>
				<Container>
					<Row
						className="d-none d-xl-flex align-items-center
					"
					>
						<Col xl={true}>
							<div className="">
								<MapGL
									ref={mapRef}
									style={{ width: "100%", height: "35rem" }}
									mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
									accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
									bounds={viewport?.bounds}
									latitude={locationLatitude}
									longitude={locationLongitude}
									zoom={viewport?.zoom}
									onViewportChange={(e) => onLocationChangePan(e)}
									viewportChangeMethod="flyTo"
								>
									<NavigationControl
										showZoom
										showCompass={false}
										position="top-right"
									/>
									{modalShowDesktop && (
										<div
											style={{ zIndex: 2 }}
											className="w-100 h-100 position-absolute start-0 top-0 "
										>
											<div
												onClick={() => setModalShowDesktop(false)}
												style={{ opacity: 0.45, zIndex: 2 }}
												className="w-100 h-100 start-0 top-0 bg-black
											"
											></div>
											<div
												style={{ zIndex: 4 }}
												className=" text-center position-absolute w-50 start-50 top-50 translate-middle"
											>
												<div
													style={{ zIndex: 5 }}
													className="bg-white h-100 w-100 px-0 mx-0 position-relative"
												>
													<div
														className="px-3 pt-2 position-absolute end-0 top-0"
														style={{ zIndex: 6 }}
													>
														{cart?.filter(
															(e) => e.airtableId === property?.airtableId
														).length > 0 && (
															<FaHeart
																style={{ cursor: "pointer", zIndex: 6 }}
																onClick={() => OnRemoveFromCart(property)}
																className="fs-4 text-dark-blue"
															/>
														)}
														{cart?.filter(
															(e) => e.airtableId === property?.airtableId
														).length < 1 && (
															<FaRegHeart
																style={{ cursor: "pointer", zIndex: 6 }}
																onClick={() => OnAddToCart(property)}
																className="fs-4 text-med-grey"
															/>
														)}
													</div>
													<div
														className="px-3 pt-2 position-absolute start-0 top-0"
														style={{ zIndex: 6 }}
													>
														<MdOutlineClear
															style={{ cursor: "pointer" }}
															className="fs-4"
															onClick={() => setModalShowDesktop(false)}
														/>
													</div>

													<div
														className="bg-white h-100 px-0 mx-0"
														onMouseEnter={() => {
															onLocationChangeScroll(
																property?.locationLatitude,
																property?.locationLongitude
															);
														}}
													>
														<Row
															className="px-0 mx-0 g-0  align-items-center"
															style={{
																borderBottom: "1px solid #f5f5f5",
															}}
														>
															<Col xs={12}>
																<ImageSlider
																	imageDataArr={property?.photos}
																	height="320px"
																/>
															</Col>
															<Col
																className="ps-4 px-lg-4 pt-3 pt-lg-1 pb-1 text-black"
																style={{ minHeight: "100%" }}
																xs={12}
															>
																<h3 className="fs-4 pb-2">{property?.name}</h3>

																<blockquote>
																	{property?.type === "Serviced Office" && (
																		<>
																			<p className="mb-1">
																				Private offices from
																			</p>
																			<h5 className="mt-1">
																				£
																				{`${
																					property?.rentPM === null ||
																					property?.rentPM === undefined
																						? "POA"
																						: `${round5(
																								property?.rentPM
																						  ).toLocaleString("en-UK")}pcm`
																				}`}
																			</h5>
																		</>
																	)}
																	{(property?.type === "Managed Office" ||
																		property?.type === "Flex Lease") && (
																		<>
																			<p className="mb-1">
																				Private floors from
																			</p>
																			<h5 className="mt-1">
																				£
																				{`${
																					floors[0]?.rentPM === null ||
																					floors[0]?.rentPM === undefined
																						? "POA"
																						: `${round5(
																								floors[0]?.rentPM
																						  ).toLocaleString("en-UK")}pcm`
																				}`}
																			</h5>
																		</>
																	)}
																</blockquote>
															</Col>
														</Row>
													</div>
												</div>
											</div>
										</div>
									)}
									return (
									<Marker
										latitude={locationLatitude}
										longitude={locationLongitude}
										// onClick={() => {
										// 	setproperty(property);
										// 	setModalShow(true);
										// }}
										onClick={() => {
											setModalShowDesktop(true);
										}}
									>
										<StaticImage
											quality="100"
											src={`../images/flex-pin copy.svg`}
											alt="Marker"
											placeholder="blurred"
											style={{
												maxWidth: "28px",
												cursor: "pointer",
											}}
											formats={["auto", "webp"]}
											className="align-middle d-inline-block"
										/>
									</Marker>
									);
								</MapGL>
							</div>
						</Col>
						{slugify(city).toLowerCase() === "london" && (
							<Col xl={{ span: 5 }}>
								<div className="ps-xl-6">
									<p className="text-black fs-5">
										<FaBuilding className="me-2" />{" "}
										{`${addressLineOne ? `${addressLineOne}, ` : ""} ${
											addressLineTwo ? `${addressLineTwo}, ` : ""
										}${city ? `${city}, ` : ""}${
											postCode ? `${postCode}` : ""
										}`}
									</p>
									<div className="p-0 m-0 pt-3 ps-2">
										{subwayStation1 !== null && (
											<TubeDistance
												station={subwayStation1}
												distanceKm={subwayStation1DistanceKm}
												walkTime={subwayStation1DurationMins}
												subwayLines={subwayStation1Lines}
												tube
											/>
										)}
										{subwayStation2 !== null && (
											<TubeDistance
												station={subwayStation2}
												distanceKm={subwayStation2DistanceKm}
												walkTime={subwayStation2DurationMins}
												subwayLines={subwayStation2Lines}
												tube
											/>
										)}
										{subwayStation3 !== null && (
											<TubeDistance
												station={subwayStation3}
												distanceKm={subwayStation3DistanceKm}
												walkTime={subwayStation3DurationMins}
												subwayLines={subwayStation3Lines}
												tube
											/>
										)}
									</div>
								</div>
							</Col>
						)}
					</Row>

					<Row className="d-xl-none">
						<Col xs={12}>
							{modalShow && (
								<MapCardModalNew
									show={modalShow}
									property={property}
									onHide={() => setModalShow(false)}
								/>
							)}
							<div className="pb-5 mt-4">
								<MapGL
									ref={mapRef}
									style={{ width: "100%", height: "70vh" }}
									mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
									accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
									bounds={viewport?.bounds}
									latitude={viewport?.latitude}
									longitude={viewport?.longitude}
									zoom={viewport?.zoom}
									onViewportChange={(e) => onLocationChangePan(e)}
									viewportChangeMethod="flyTo"
								>
									<NavigationControl
										showZoom
										showCompass={false}
										position="top-right"
									/>
									return (
									<Marker
										latitude={locationLatitude}
										longitude={locationLongitude}
										onClick={() => {
											setModalShow(true);
										}}
										// onClick={() => onPinClick(property)}
									>
										<StaticImage
											quality="100"
											src={`../images/flex-pin copy.svg`}
											alt="Marker"
											placeholder="blurred"
											style={{
												maxWidth: "28px",
												cursor: "pointer",
											}}
											formats={["auto", "webp"]}
											className="align-middle d-inline-block"
										/>
									</Marker>
									);
								</MapGL>
							</div>

							{/* <MapGL

                //   ref={mapRef}
                style={{ width: "100%", height: "350px" }}
                mapStyle="mapbox://styles/mapbox/light-v9"
                accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
                bounds={viewport?.bounds}
                // latitude={viewport?.latitude}
                // longitude={viewport?.longitude}
                latitude={locationLatitude}
                longitude={locationLongitude}
                zoom={viewport?.zoom}
                onViewportChange={(e) => onLocationChangePan(e)}
                viewportChangeMethod="flyTo"
              >
                <GeolocateControl
                  onGeolocate={(e) => settingVal(e, "viewportValue")}
                  position="top-right"
                />

                <Marker
                  key={airtableId}
                  longitude={locationLongitude}
                  latitude={locationLatitude}
                  //   onClick={() => onPinClick(property)}
                >
                  <FaMapMarkerAlt
                    style={{ cursor: "pointer" }}
                    className={`fs-4  text-primary`}
                  />
                </Marker>

                {showPopup && (
                  <Popup
                    longitude={activePopup.node.locationLongitude}
                    latitude={activePopup.node.locationLatitude}
                    closeButton={false}
                    closeOnClick={false}
                  >
                    <div className=" position-relative w-100">
                      <Button
                        style={{ top: "0rem", right: "0rem" }}
                        onClick={() => setShowPopup(false)}
                        variant="secondary"
                        className=" position-absolute"
                      >
                        X
                      </Button>
                      <h2 className="fs-5">{activePopup.node.name}</h2>
                      <GatsbyImage
                        style={{ width: "200px", height: "150px" }}
                        image={
                          activePopup.node.featuredImageNew.childImageSharp
                            .gatsbyImageData
                        }
                        alt="Featured Image"
                      />
                    </div>
                  </Popup>
                )}
              </MapGL> */}
						</Col>
						{slugify(city).toLowerCase() === "london" && (
							<Col>
								<p className="text-black fs-5">
									<FaBuilding className="me-2" />{" "}
									{`${addressLineOne ? `${addressLineOne}, ` : ""} ${
										addressLineTwo ? `${addressLineTwo}, ` : ""
									}${city ? `${city}, ` : ""}${postCode ? `${postCode}` : ""}`}
								</p>
								<div className="p-0 m-0 pt-3 ps-2">
									{subwayStation1 !== null && (
										<TubeDistance
											station={subwayStation1}
											distanceKm={subwayStation1DistanceKm}
											walkTime={subwayStation1DurationMins}
											subwayLines={subwayStation1Lines}
											tube
										/>
									)}
									{subwayStation2 !== null && (
										<TubeDistance
											station={subwayStation2}
											distanceKm={subwayStation2DistanceKm}
											walkTime={subwayStation2DurationMins}
											subwayLines={subwayStation2Lines}
											tube
										/>
									)}
									{subwayStation3 !== null && (
										<TubeDistance
											station={subwayStation3}
											distanceKm={subwayStation3DistanceKm}
											walkTime={subwayStation3DurationMins}
											subwayLines={subwayStation3Lines}
											tube
										/>
									)}
								</div>
							</Col>
						)}
					</Row>
				</Container>
			</section>
			<section className="d-lg-none">
				<Container>
					<Row>
						<Col className="" lg={{ span: 5, offset: 1 }}>
							<div className="px-xl-6 mb-4">
								<PreferToTalk title="Ready to talk?" />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			{(amenities?.length > 0 || services?.length > 0) && (
				<section className="mt-lg-4">
					<Container>
						<Row>
							<Col>
								<h2 className="fs-1 text-center text-lg-start mt-4 ">
									Facilities
								</h2>
							</Col>
						</Row>
						<Row className="mb-5 mt-4">
							<Col lg={5} className="">
								<FacilitiesBlock array={amenities} title="Building Features" />
							</Col>
							<Col lg={{ span: 5, offset: 2 }} className=" mt-4 mt-lg-0">
								<FacilitiesBlock array={services} title="Building Services" />
							</Col>
						</Row>
					</Container>
				</section>
			)}
			<section>
				<Container>
					<Row>
						<Col>
							<h2 className="fs-1 text-center pb-7 mt-5 ">
								Other Popular Buildings
							</h2>
						</Col>
					</Row>
					<Row className="g-4">
						{/* {shortOffices

              ?.filter((office) => office.node.name !== propertyName)
              .slice(0, 3)
              .map((office, i) => {
                return (
                  <Col xl={4} lg={6} xs={12}>
                    <VerticalListingsPropertyCard
                      property={office}
                      index={i}
                      className="h-100"
                      features={office.node.features}
                      imageHeight="200px"
                    />
                  </Col>
                );
              })} */}
						<FeaturedProperties
							colWidthXL={4}
							colWidthLG={6}
							CardType={VerticalListingsPropertyCard}
							properties={shortOffices
								?.filter((office) => office.node.fixedId !== fixedId)
								.slice(0, 3)}
						/>
					</Row>
				</Container>
			</section>
			<section className="py-lg-6 py-3" id="contact">
				<Container>
					<div
						style={{
							boxShadow: "0px 1px 20px #00000029",
							borderRadius: "12px",
						}}
						className="py-3 py-md-5 px-3 px-md-5 mb-3  bg-white"
					>
						<Row>
							<Col>
								<h3>Get in touch</h3>
							</Col>
						</Row>
						<Row>
							<Col lg={4}>
								<p>
									Fill in your contact details below and we'll put you in touch
									with one of our experts
								</p>
							</Col>
						</Row>
						<ContactFormHome />
					</div>
					<ExpertCard />
				</Container>
			</section>
		</Layout>
	);
};

export default Listings;

export const pageQuery = graphql`
	query PropertyById($id: String!) {
		property: airtableProperties(id: { eq: $id }) {
			subwayStation1
			live
			subwayStation1DistanceKm
			subwayStation1DurationMins
			subwayStation1Lines
			subwayStation2
			subwayStation2DistanceKm
			subwayStation2DurationMins
			subwayStation2Lines
			subwayStation3
			subwayStation3DistanceKm
			subwayStation3DurationMins
			subwayStation3Lines
			airtableId
			fixedId
			locationLatitude
			locationLongitude
			virtualTourLink
			name
			services
			amenities
			desksTo
			features
			description
			addressLineOne
			addressLineTwo
			city
			postCode
			type
			desksFrom
			rentPM
			videoId
			photos
			floorsAvailable {
				desksTo
				desksFrom
				name
				rentPM
			}
		}

		properties: allAirtableProperties(
			filter: {
				isFloor: { nin: 1 }
				hasFeatures: { eq: 1 }
				hasPhotos: { eq: 1 }
				live: { eq: true }
			}
			limit: 4
		) {
			edges {
				node {
					subwayStation1
					live
					subwayStation1DistanceKm
					subwayStation1DurationMins
					subwayStation2
					subwayStation2DistanceKm
					subwayStation2DurationMins
					airtableId
					locationLatitude
					locationLongitude
					name
					city
					fixedId
					services
					amenities
					rentPM
					desksTo
					features
					description
					photos
					addressLineOne
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
